.MapCluster {
	position: relative;
	&__wrap {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		padding: 3px;
		background-color: #fff;
		border-radius: 32px;
		border: 1px solid #04040629;
		min-width: 40px;
		height: 40px;
		box-sizing: border-box;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateX(-50%) translateY(-50%);
		cursor: pointer;
	}

	&__val {
		padding: 4px 8px;
		font-weight: 700;
		font-size: 22px;
		line-height: 22px;
		vertical-align: top;
		border-radius: 32px;
	}
}

.MapCluster_visited {
	.MapCluster {
		&__val {
			background-color: #04040614;
		}
	}
}

.MapCluster:hover,
.MapCluster_hover {
	.MapCluster {
		&__wrap {
			border: 2px solid #040406;
			z-index: 2;
		}
	}
}
