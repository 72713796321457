.MarkerPoint {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #fff;
	box-sizing: border-box;
	border: 4px solid #8835f4;
	cursor: pointer;

	&_active {
		background: #8835f4;
	}
	&:hover,
	&_hover {
		background: #8835f4;
	}
}
