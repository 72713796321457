@use 'sass:math';
@import '../../styles/_meta';

.MarkerPrice {
	position: absolute;
	&__cols {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		background-color: #fff;
		border: 1px solid #04040629;
		box-shadow: 0px 2px 4px 0px #00000014;
		padding: 0 8px;
		height: 28px;
		border-radius: 32px;
		cursor: pointer;
		align-items: center;
		box-sizing: border-box;
	}

	&__val {
		display: block;
		font-weight: 700;
		font-size: 12px;
		line-height: 1;
		white-space: nowrap;
	}

	&__fav {
		display: inline-block;
		vertical-align: top;
		width: 18px;
		height: 16px;
		margin-top: -2px;
		margin-bottom: -2px;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjE0NzcgMS4wMzM1NUMxMi4zNTE1IDAuOTM2NzM3IDExLjU0MzcgMS4wNTg2NCAxMC44MTI0IDEuMzg2QzEwLjA4MTIgMS43MTMzNiA5LjQ1NDMyIDIuMjMzNjIgOS4wMDAxOSAyLjg5MDE1QzguNDgwMDIgMi4xMzc1NyA3LjczNDc1IDEuNTY2MzUgNi44Njk3NSAxLjI1NzI1QzYuMDA0NzUgMC45NDgxMzggNS4wNjM3NyAwLjkxNjc4IDQuMTc5ODQgMS4xNjc2QzMuMjk1OTEgMS40MTg0MyAyLjUxMzc0IDEuOTM4NzUgMS45NDM5MSAyLjY1NUMxLjM3NDA4IDMuMzcxMjYgMS4wNDU0MSA0LjI0NzIyIDEuMDA0MzYgNS4xNTkwNUMwLjg5MzQ1MyA3LjY2ODY2IDIuOTE4MiA5LjQxNzcxIDQuNjkyNzYgMTEuMTQ4OEM2LjE2MDM4IDEyLjU4MjkgOC4yMjY0IDE0LjQwODggOC44MTk2NCAxNC45MzYzQzguODY2NzEgMTQuOTc3NCA4LjkyNzIyIDE1IDguOTg5ODggMTVDOS4wNTI1MyAxNSA5LjExMzA0IDE0Ljk3NzQgOS4xNjAxMSAxNC45MzYzQzkuNzczOTggMTQuNDA4OCAxMS44Mzc0IDEyLjU4MjkgMTMuMzA1IDExLjE1MTRDMTUuMDc3IDkuNDE3NzEgMTcuMTAxOCA3LjY2MDk4IDE2Ljk5NiA1LjE1OTA1QzE2Ljk0NTQgNC4xMzE5NiAxNi41MzE4IDMuMTU1NDQgMTUuODI4MSAyLjQwMDk4QzE1LjEyNDMgMS42NDY1MSAxNC4xNzUzIDEuMTYyMzYgMTMuMTQ3NyAxLjAzMzU1WiIgZmlsbD0iI0ZGQUNGNyIgc3Ryb2tlPSIjQTIwMDdFIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
		@include ml(6px);
	}
}

.MarkerPrice_visited {
	.MarkerPrice {
		&__cols {
			background: linear-gradient(
					0deg,
					rgba(4, 4, 6, 0.08),
					rgba(4, 4, 6, 0.08)
				),
				linear-gradient(0deg, #ffffff, #ffffff);
			border: 1px solid #0404063d;
		}
	}
}

.MarkerPrice:hover:not(.MarkerPrice_active),
.MarkerPrice_hover:not(.MarkerPrice_active) {
	.MarkerPrice__cols {
		border: 1px solid #040406;
		box-shadow: 0 0 0 1px #040406;
	}
}

.MarkerPrice_active {
	z-index: 10;
	.MarkerPrice {
		&__cols {
			background-color: #040406;
			color: #fff;
			border: 1px solid #040406;
		}
	}
}
