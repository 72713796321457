@import 'swiper/css';
@import 'swiper/css/grid';

@import './base';

@import '../components//MarkerPoint/style.scss';
@import '../components//MarkerPrice/style.scss';
@import '../components//MapCluster/style.scss';

.htmlFixed {
	overflow: hidden;
}
