.wrap {
	position: fixed;
	z-index: 100000;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	&_absolute {
		position: absolute;
	}
}

.img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}
