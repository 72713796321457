body {
	-webkit-tap-highlight-color: transparent;
	font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, Arial,
		sans-serif;
	color: #040406;
	margin: 0;
	position: relative;
	overflow-x: hidden;
}

*:focus {
	outline: 0;
}

a {
	outline: none;
	text-decoration: none;
	color: #040406;
}

ol,
ul {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
}

img {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-weight: initial;
	font-size: inherit;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

address {
	display: inline;
	font-style: normal;
}

button {
	appearance: none;
	color: inherit;
	display: inline-block;
	cursor: pointer;
	background: none;
	margin: 0;
	font: inherit;
	padding: 0;
	border: none;

	-webkit-tap-highlight-color: transparent;
}

hr {
	margin-block-start: 0;
	margin-block-end: 0;
}

[type='text'],
[type='email'],
[type='url'],
[type='search'],
[type='password'],
[type='tel'] {
	appearance: none;
	padding: 0;
	margin: 0;
	border: none;

	background-color: #fff;

	color: inherit;
	font: inherit;
}

input[type='radio'] {
	appearance: none;
	padding: 0;
	margin: 0;
	border: none;
	width: 0;
	height: 0;

	color: inherit;
	font: inherit;
}

input[type='number'] {
	-moz-appearance: textfield;
	padding: 0;
	border: none;
	font-family: inherit;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	display: none;
}

input[type='reset'] {
	background-color: transparent;
	border: none;
	font-size: 1rem;
	font-weight: 500;
	font-family: inherit;
}

ul {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
}

p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

summary::-webkit-details-marker {
	display: none;
}
